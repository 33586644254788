import { Spinner } from 'react-bootstrap';
import '../styles/loading.scss';

const Loading = (props) => {

  return (
    <div id="loading-component">
      <Spinner animation="border" variant="secondary" />
    </div>
  );
}

export default Loading;
