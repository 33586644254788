// import { Spinner } from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import '../styles/screen.scss';

const Screen = (props) => {
  const screenId = props.match.params.id;
  let screenTitle = '';
  let bodyContent = '';

  // point west: CQSL9J
  // roseville 1: VJQLHU
  // roseville 2: TDS235

  if(screenId === 'CQSL9J') {
    screenTitle = 'Welcome to Point West Clinic';
    bodyContent = `
    <div class="col-4">
      <table class="w-100">
        <tr>
          <th>Departments</th>
          <th>Floor</th>
        </tr>
        <tr>
          <td>Hand Therapy</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Integrative Medicine</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Neuropsychology</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Occupational Therapy</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Orthopedics: Hand & Total Joint</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Pain Management & Rehabilitation</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Physical Medicine & Rehabilitation Therapies</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Physical Therapy</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Plastic Surgery: Hand</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Radiology</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Speech Therapy</td>
          <td>1</td>
        </tr>
        <tr>
          <td>Urology</td>
          <td>2</td>
        </tr>
      </table>
    </div>
    <div class="col-2"></div>
    <div class="col-3">
      <table class="w-100">
        <tr>
          <th>Physicians</th>
          <th>Floor</th>
        </tr>
        <tr>
          <td>Canvasser MD, Noah</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Dall’Era MD, Marc</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Dossett MD, Michelle</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Frank MD, Rochelle</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Ingram LAC, Isa</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Johns PA, Joei Ho-Yee</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Leong PA, Keoki</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Li MD, Andrew</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Lim DO, Angela</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Mcnulty DO, Heidi</td>
          <td>2</td>
        </tr>
        <tr>
          <td>MacLean, Kathryn</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Macmeo NP, Neasha</td>
          <td>2</td>
        </tr>
      </table>
    </div>
    <div class="col-3">
      <table class="w-100">
        <tr>
          <th>Physicians</th>
          <th>Floor</th>
        </tr>
        <tr>
          <td>Oeur NP, Christine</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Okamoto MD, Daniel</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Paiva MD, Tobi</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Pereira MD, Clifford</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Perinovic PA, Steven</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Powelson MD, Ian</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Rinaldi DO, Peter</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Rotschild MD, Jennifer</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Sosik PA, Kimberly</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Sood MD, Ravi</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Wagner PA, Paula</td>
          <td>2</td>
        </tr>
        <tr>
          <td>Warrick MD, Alexandra</td>
          <td>2</td>
        </tr>
      </table>
    </div>
    `;

  } else if(screenId === 'VJQLHU' || screenId === 'TDS235') {
    screenTitle = 'Roseville Clinic';
    bodyContent = `
      <div class="col">
      <p class="header">Allergy</p>
        <p class="listing">Vinay Goswamy, M.D.</p>
        <p class="listing">Rosemary Hallett, M.D.</p>
      <hr />
      <p class="header">Cardiology</p>
      	<p class="listing">Victor Bonilla, M.D.</p>
      	<p class="listing">Radhika Bukkapatnam, M.D.</p>
      <hr />
      <p class="header">Dermatology</p>
        <p class="listing">Lawrence Chan, M.D.</p>
        <p class="listing">Philina Lamb, M.D.</p>
        <p class="listing">Linda Lee, M.D.</p>
      <hr />
      <p class="header">Endocrinology</p>
        <p class="listing">Jose Lado, M.D.</p>
      	<p class="listing">Mark Moriwaki, M.D.</p>
        <p class="listing">Sonal Phatak, M.D.</p>
        <p class="listing">Sima Sitaula, N.P.</p>
      <hr />
      <p class="header">Family Medicine</p>
        <p class="listing">David Mach, D.O.</p>
        <p class="listing">Arthur Mash, M.D.</p>
      	<p class="listing">Nesser Ramirez, M.D.</p>
      	<p class="listing">Afshine Ghaemi, M.D.</p>
      	<p class="listing">Renata Segal, D.O.</p>
      </div>
      <div class="col">
      <p class="header">Internal Medicine</p>
        <p class="listing">Ishtleen Bhatia, M.D.</p>
      	<p class="listing">Karin Kordas, M.D.</p>
      	<p class="listing">Mushfeka Sharif, M.D.</p>
      	<p class="listing">Yunli Zheng, M.D.</p>
      <hr />
      <p class="header">Nephrology</p>
        <p class="listing">Hiba Hamdan, M.D.</p>
        <p class="listing">Niti Madan, M.D.</p>
      <hr />
      <p class="header">Neurology</p>
      	<p class="listing">Ashok Dayananthan, M.D.</p>
        <p class="listing">Sophie Teng, M.D.</p>
      <hr />
      <p class="header">OB/GYN</p>
      	<p class="listing">Yan Zhao, M.D.</p>
      	<p class="listing">Bahareh Nejad, M.D.</p>
      <hr />
      <p class="header">Ophthalmology</p>
        <p class="listing">Jeffrey Caspar, M.D.</p>
        <p class="listing">Rebecca Chen, M.D.</p>
        <p class="listing">Marcela Estrada, M.D.</p>
        <p class="listing">Lily Koo Lin, M.D.</p>
        <p class="listing">Kareem Moussa, M.D.</p>
      </div>
      <div class="col">
      <p class="header">Optometry</p>
      	<p class="listing">Brooke Chang, O.D.</p>
      	<p class="listing">Kaaryn Pederson, O.D.</p>
        <p class="listing">Hai Tong, O.D.</p>
      <hr />
      <p class="header">Orthopaedics</p>
      	<p class="listing">Susan Tseng, M.D.</p>
      <hr />
      <p class="header">Pediatrics</p>
      	<p class="listing">Anoosh Malaekeh, M.D.</p>
      	<p class="listing">Veeraparn Kanchananakhin, M.D.</p>
      	<p class="listing">Olivia Hoang-Kelada, M.D.</p>
      <hr />
      <p class="header">Rheumatology</p>
        <p class="listing">Harbrinder Sandhu, M.D.</p>
      <hr />
      <p class="header">Urogynecology</p>
        <p class="listing">Avita Pahwa, M.D.</p>
      </div>`;
  } else {
    // do something here to return to another route, it means the
    // screen ID wasn't found
  }

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          @import url("https://use.typekit.net/tpe0dmg.css");
        `}</style>
      </Helmet>

      <div id="screen-component" className="d-flex flex-column">
        <div id="display-header" className="d-flex py-3 px-4">
          <div className="left flex-fill">
            <img src="https://eams-dsm.s3.amazonaws.com/ucdh/ucdh-logo.png" onDoubleClick={() => window.location.reload() } />
            <span>{screenTitle}</span>
          </div>
          <div className="center flex-fill text-center"></div>
          <div className="right flex-fill text-right">
          <Moment interval={15000} format="h:mma" className="display-time" />
          <Moment interval={3600000} format="dddd, MMMM Do" className="display-date" />
          </div>
        </div>

        <div id="display-body" className="d-flex flex-grow-1 flex-overflow p-4">
          <div className="row w-100">
            {parse(bodyContent)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Screen;
