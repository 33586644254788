import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { Helmet } from 'react-helmet';
import { Switch, Route } from "react-router-dom";

import Loading from "./views/Loading";
import Screen from "./views/Screen";
import Admin from "./views/Admin";

const App = () => {
  const [isLoading, setLoading] = useState(false);

  // useEffect(() => {
  //   if(pullLocal === true) {
  //     /* testing, pull local config file */
  //     console.log('pulling local config')
  //     let newConfig = require('../../_json/'+hostname+'.json');
  //     setConfig(newConfig);
  //     setLoading(false);
  //
  //   } else {
  //     /* otherwise get the real one from AWS */
  //     console.log('pulling live config')
  //     axios.post('/aws/presigned', {
  //       bucket: 'eams-config',
  //       key: 'configs/'+hostname+'.json'
  //     }).then(res => {
  //       axios.get(res.data).then(res2 => {
  //        let newConfig = res2.data;
  //        setConfig(newConfig);
  //        setLoading(false);
  //       })
  //     })
  //   }
  // }, []);

  if (isLoading) {
      return (
        <Loading />
      )
    }

  return (
    <>
      <Helmet>
        <title>Wayfinder</title>
      </Helmet>
      <Switch>
        <Route exact path="/admin/:id" render={(props) => (
          <Admin {...props} />
        )} />

        <Route exact path="/gradient" render={() => {window.location.href="display/gradient.html"}} />
        <Route exact path="/:id" render={(props) => (
          <Screen {...props} />
        )} />

        <Route exact path="/" render={() => {window.location.href="display/index.html"}} />
        <Route render={(props) => ( <div>Coming soon</div> )} />
      </Switch>
    </>
  );
}

export default App;
