import { Spinner } from 'react-bootstrap';
import '../styles/loading.scss';

const Admin = (props) => {
  console.log(props.match.params.id)

  let contentId = props.match.params.id

  if(contentId === '9ac1fb9d-bfb2-4983-9e9e-18682033bbf0') {
    console.log('roseville')
  } else if(contentId === 'b8a8cbf0-091d-443c-a133-5a0d45bf58ae') {
    console.log('point west')
  }

  return (
    <div id="admin-component">
      hi
    </div>
  );
}

export default Admin;
